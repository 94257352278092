import axios from 'axios';
import $auth from '../../common/auth';

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const VUE_APP_SALESFORCE_URL = VUE_APP_API_URL + '/salesforce/knowledge/';

const getArticleById = (id) => {
    const url = `${VUE_APP_SALESFORCE_URL}findKBArticleById/${id}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const searchMainDoubts = (search = null) => {
    const url = `${VUE_APP_SALESFORCE_URL}findKBArticlesMainDoubts/${search && search}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

export {
    getArticleById,
    searchMainDoubts
};
